import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Offers from "../components/Home/Offers";
import Appointment from "../components/Home/Appointment";
import Reference from "../components/Home/Reference";
import Hero from "../components/Home/Hero";

import "twin.macro";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <>
        <SEO title="KÜCHENHAUS MARKETING" />
        <Hero />
        <Offers />
        <Appointment />
        <Reference />

        <main>
          <title>Home Page</title>
        </main>
      </>
    </Layout>
  );
};

export default IndexPage;
