import React from "react";
import { graphql, StaticQuery } from "gatsby";
import "twin.macro";
import { Link } from "gatsby";

import BackgroundImage from "gatsby-background-image";

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "Kontakt-2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
          style={{
            backgroundColor: "#ffffff",
            height: "90vh",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            paddingTop: "0px",
            paddingRight: "30px",
            paddingLeft: "30px",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div tw="grid md:grid-cols-2">
            <div tw="md:col-start-2 md:col-end-3">
              <div tw=" h-auto mx-auto py-12 flex flex-col items-center justify-center text-center bg-white border-20 border-primary px-10 ">
                {/* <div tw=" bg-white p-12 h-full"> */}
                <p
                  className=""
                  tw="font-bold text-xl sm:text-2xl  md:text-3xl text-center"
                >
                  VEREINBAREN SIE NOCH HEUTE EINEN TERMIN
                </p>
                <p
                  className=""
                  tw="text-lg sm:text-xl font-medium text-center font-semibold"
                >
                  Unsere Erstberatung ist kostenlos und unverbindlich. Wir
                  beraten Sie professionell und erfolgsorientiert.
                </p>
                <hr tw="w-3/4 mx-auto bg-primary h-2 my-4" />
                <div tw="flex mx-auto text-center">
                  <Link
                    to="/contact"
                    tw="font-bold text-white bg-primary px-4 py-2 rounded inline-block mx-auto"
                  >
                    ANGEBOT EINHOLEN
                  </Link>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

export default BackgroundSection;
