import React from "react";
import { graphql, StaticQuery } from "gatsby";
// import styled from 'styled-components'
import "twin.macro";
import { styled } from "twin.macro";

import BackgroundImage from "gatsby-background-image";
const size = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1200px",
};
const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
};
const breakpoint = { size, device };
const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "Background_Frequenz.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <>
          <BackgroundImage
            tw=""
            Tag="section"
            className={className}
            fluid={imageData}
          >
            <div tw="flex justify-center flex-col h-full items-center text-center text-2xl sm:text-3xl md:text-5xl lg:text-6xl z-30">
              <h1 tw="text-primary font-black">360° KÜCHENHAUS MARKETING</h1>
              <h1 tw="font-black">–</h1>
              <h1 tw="font-black">FREQUENZ ERHÖHEN</h1>
            </div>
          </BackgroundImage>
        </>
      );
    }}
  />
);
const StyledBackgroundSection = styled(BackgroundSection)`
  position: relative;
  z-index: -1;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(255, 255, 255);
  pointer-events: none;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 50vh;
  }
  @media (min-width: 768px) {
    height: 597px;
    width: 100vw;
  }
`;
export default StyledBackgroundSection;
