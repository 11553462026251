import * as React from "react";
import "twin.macro";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

function Card({ imageComp, headline, text, link }) {
  /* TODO:

  https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#restrictions-on-using-staticimage

  If you find yourself wishing you could use a prop for the image src
  then it’s likely that you should be using a dynamic image.
 */

  return (
    <div tw="bg-white h-auto flex flex-col justify-between shadow-md transform transition duration-500  hover:scale-110">
      {imageComp}

      <h5 tw="mb-4 text-lg leading-6 font-medium text-black text-center">
        {headline}
      </h5>

      <p tw="mb-4 text-base leading-6 text-black text-center">{text}</p>

      <Link
        to={link}
        tw="mb-4 bg-primary hover:bg-red-700 px-4 py-2 rounded-md inline-block mx-auto text-white text-center"
      >
        MEHR INFORMATIONEN
      </Link>
    </div>
  );
}

Card.propTypes = {
  imageComp: PropTypes.element,
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default function Offers() {
  return (
    <div tw="pb-8 md:pb-16">
      <div className="">
        <h3 tw="mt-8 text-center font-black text-black text-2xl md:text-4xl">
          UNSER ANGEBOT FÜR SIE
        </h3>
      </div>
      <div
        className=""
        tw="w-5/6 md:w-2/3 lg:w-4/6 xl:w-1/2 h-auto mx-auto flex justify-center"
      >
        <StaticImage
          src="../../images/blasenbild-1.jpeg"
          alt="UNSER ANGEBOT FÜR SIE"
          tw="transform transition duration-500 hover:scale-110 my-10"
        />
      </div>
      <div tw="bg-white overflow-hidden">
        <div tw=" max-w-screen-xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div tw=" lg:grid lg:grid-cols-3 lg:col-gap-8">
            <div tw="mt-10 sm:mt-0 grid grid-cols-1 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 sm:col-gap-10 sm:row-gap-20 md:col-gap-10 md:row-gap-16 lg:col-gap-6 md:row-gap-12 sm:col-span-2 lg:col-span-4 lg:mt-0">
              <Card
                imageComp={
                  <StaticImage
                    src="../../images/Menue_Online.jpg"
                    tw="w-full lg:w-auto mb-4"
                    alt="online marketing"
                  />
                }
                headline="ONLINE MARKETING"
                text="Wir begleiten Sie bei Ihrer Digitalisierung"
                link="/ourservices/onlinemarketing"
              />
              <Card
                imageComp={
                  <StaticImage
                    src="../../images/Menu_Offline.jpg"
                    tw="w-full lg:w-auto mb-4"
                    alt="offline marketing"
                  />
                }
                headline="OFFLINE MARKETING"
                text="Messen, Events und Promotion"
                link="/ourservices/offlinemarketing"
              />
              <Card
                imageComp={
                  <StaticImage
                    src="../../images/Menue_Kochen.jpg"
                    tw="w-full lg:w-auto mb-4"
                    alt="KOCHKURSE"
                  />
                }
                headline="KOCHKURSE"
                text="Wir machen den Kochkurs in Ihrem Haus zum Erlebnis"
                link="/ourservices/cooking"
              />
              <Card
                imageComp={
                  <StaticImage
                    src="../../images/Menue_Backen.jpg"
                    tw="w-full lg:w-auto mb-4"
                    alt="BACKEN"
                  />
                }
                headline="BACKEN"
                text="Gerätetest und Frequenz mit tollem Werbeeffekt"
                link="/ourservices/baking"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
